import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Files from '../components/Files.vue'
import Index from '../components/IndexPage.vue'
import Tables from '../components/Tables.vue'
import Versions from '../components/Versions.vue'

Vue.use(VueCookies)

export function authControl(paths, role_id) {
  // 筛选出没权限访问的路由
  if (!role_id) return []
  return paths.filter(path => !(path.maxRole && role_id > path.maxRole))
}

export default [
  {
    icon: 'mdi-ticket',
    title: '首页',
    path: "/",
    component: Index,
    single: true,
    visible: false,
    requireAuth: true
  },
  {
    icon: 'mdi-table-settings',
    path: '/iOSClients',
    title: 'iOS CID 管理',
    component: Tables,
    single: true,
    requireAuth: true
  },
  {
    icon: 'mdi-table-settings',
    path: '/ClientManagement',
    title: 'CID 管理',
    component: Tables,
    single: true,
    requireAuth: true
  },
  {
    icon: 'mdi-view-grid-plus',
    path: '/AppSites',
    title: '站点信息',
    component: Tables,
    single: true,
    requireAuth: true
  },
  {
    icon: 'mdi-tag-heart',
    path: '/AuthUnit',
    title: '单项权限信息',
    component: Tables,
    single: true,
    requireAuth: true
  },
  {
    icon: 'mdi-tag-multiple',
    path: '/AuthGroup',
    title: '权限组',
    component: Tables,
    single: true,
    requireAuth: true
  },
  {
    icon: 'mdi-calendar-star',
    path: '/AppEvents',
    title: 'App 活动',
    component: Tables,
    single: true,
    requireAuth: true
  },
  {
    icon: 'mdi-image-auto-adjust',
    path: '/AppSiteImage',
    title: '站点图片',
    component: Tables,
    single: true,
    requireAuth: true
  },
  {
    icon: 'mdi-image-auto-adjust',
    path: '/AppBanner',
    title: 'Banner 管理',
    component: Tables,
    single: true,
    requireAuth: true
  },
  {
    icon: 'mdi-file-cloud',
    path: '/files',
    title: '文件管理',
    component: Files,
    single: true,
    requireAuth: true
  },
  {
    icon: 'mdi-book-cog',
    path: '/version',
    title: '版本管理',
    component: Versions,
    single: true,
    requireAuth: true
  },
  {
    icon: 'mdi-account-cog',
    title: '用户管理',
    items: [
      {title: "用户组", path: "/UserRoles", component: Tables},
      {title: "用户管理", path: "/VueUser", component: () => import("../components/User.vue")}
    ],
    requireAuth: true,
    maxRole: 1
  },
  {
    icon: 'mdi-ticket',
    items: [
      {title: "Page1", path: "/pages/1"},
      {title: "Page2", path: "/pages/2"},
      {title: "Page3", path: "/pages/3"},
      {title: "Page4", path: "/pages/4"}
    ],
    title: '多页面',
    visible: false
  },
  {
    icon: 'mdi-ticket',
    items: [
      {title: "Page5", path: "/pages/5"},
      {title: "Page6", path: "/pages/6"},
      {title: "Page7", path: "/pages/7"},
      {title: "Page8", path: "/pages/8"}
    ],
    title: '多页面2',
    visible: false
  }
]
